/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: '';
  background: color-mix(in srgb, var(--background-color), transparent 30%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 52px;
  font-weight: 700;
}

.hero p {
  margin: 10px 0 0 0;
  font-size: 24px;
  color: var(--heading-color);
}

.hero .btn-get-started {
  color: var(--default-color);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 40px;
  margin: 30px 0 0 0;
  border-radius: 4px;
  transition: 0.5s;
  border: 2px solid color-mix(in srgb, var(--default-color), transparent 50%);
}

.hero .btn-get-started:hover {
  color: var(--contrast-color);
  background: var(--accent-color);
  border-color: var(--accent-color);
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
  }

  .hero p {
    font-size: 18px;
  }
}
