/*** Appointment ***/
.appointment {
  background: linear-gradient(var(--background-color), rgba(57, 11, 61, 0.9), var(--background-color)), url(../../../assets/img/about.jpg) center center no-repeat;
  background-size: cover;
}

@keyframes pulseRotate {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}

/*** Testimonial ***/
.animated.pulse {
  animation: pulseRotate 4s infinite ease-in-out;
}

.testimonial-left,
.testimonial-right {
  position: relative;
}

.testimonial-left img,
.testimonial-right img {
  position: absolute;
  padding: 5px;
  border: 1px dashed var(--accent-color);
  border-radius: 10px;
}

.testimonial-left img:nth-child(1),
.testimonial-right img:nth-child(3) {
  width: 70px;
  height: 70px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-left img:nth-child(2),
.testimonial-right img:nth-child(2) {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}

.testimonial-left img:nth-child(3),
.testimonial-right img:nth-child(1) {
  width: 50px;
  height: 50px;
  bottom: 10%;
  right: 10%;
}

.list-group {
  width: 400px !important;
}

.list-group-item {
  margin-top: 10px;
  border-radius: none;
  background: var(--accent-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.list-group-item:hover {
  transform: scaleX(1.1);
}

.check {
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.list-group-item:hover .check {
  opacity: 1;
}

.about span {
  font-size: 12px;
  margin-right: 10px;
}

.list-group-item .check input[type='checkbox']:checked + .check {
  opacity: 1;
}
