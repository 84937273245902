/* Fonts */
:root {
  --default-font: 'Roboto', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --heading-font: 'Poppins', sans-serif;
  --nav-font: 'Poppins', sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #160118; /* Background color for the entire website, including individual sections */
  --default-color: #fff; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #fff; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #9a031e; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #160118; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #ffffff; /* The default color of the main navmenu links */
  --nav-hover-color: #9a031e; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #555555; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #9a031e; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* ff7f5d */
/* bf406e */

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #160118;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #160118;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #160118;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs --------------------------------------------------------------*/
.page-title {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 160px 0 80px 0;
  text-align: center;
  position: relative;
}

.page-title:before {
  content: '';
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  position: absolute;
  inset: 0;
}

.page-title h1 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 10px;
}

.page-title .breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.page-title .breadcrumbs ol li + li {
  padding-left: 10px;
}

.page-title .breadcrumbs ol li + li::before {
  content: '/';
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 100px;
  overflow: clip;
}

@media (max-width: 1199px) {
  section,
  .section {
    scroll-margin-top: 66px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding: 30px 0;
  margin-bottom: 30px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  position: relative;
  z-index: 2;
}

.section-title span {
  position: absolute;
  top: 4px;
  color: color-mix(in srgb, var(--heading-color), transparent 95%);
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 1;
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .section-title span {
    font-size: 38px;
  }
}
